import React, {useRef, useState} from 'react';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styles from './ExpandingCard.module.scss';
import {graphql, useStaticQuery} from "gatsby";
import styled from 'styled-components';
import toast from '../Toast/Toast';

const Text = styled.div`
  height: ${({expanded}) => expanded ? 'auto' : 0};
  box-sizing: border-box;
  overflow: hidden;
  font-size: 18px;
  position: relative;
  .expanding-card-container {
    padding: ${({expanded}) => expanded ? '16px 32px 24px' : 0};
  }
  @media (max-width: 960px) {
    line-height: 24px;
    font-size: 14px;
    .expanding-card-container {     
        padding: ${({expanded}) => expanded ? '16px' : 0};
      }
    }

    h3 {
      margin-bottom: 4px;
      line-height: 32px;
      font-size: 24px;
    }

    h6 {
      margin-bottom: 16px;
      padding: 0 35px;
    }
`

const Icon = styled.img`
  margin: ${({clientCard}) => clientCard ? '0 auto' : '0 20px 0 0'};
  width: ${({clientCard}) => clientCard ? '80%' : '48px'};
  max-height: 100%;
  object-fit: contain;
`

const HeaderButton = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  user-select: none;
  padding: ${({custom}) => custom ? '0 32px 0 0' : '24px 32px'};
  height: ${({clientCard}) => clientCard ? '172px' : '104px'};
  min-height: 104px;
  &:focus {
    outline: none;
  }
  @media (max-width: 960px) {
    padding: ${({custom}) => custom ? '0 24px 0 0' : '16px 24px'};
  }
`

const ExpandingCard = ({
                         hasLink,
                         cardId,
                         custom,
                         expander,
                         collapser,
                         noIndicator,
                         img,
                         smallCard,
                         clientCard,
                         children,
                         expandedCardHeaderClassName
                       }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const anchorRef = useRef(null);
  React.useEffect(() => {
    const url = typeof window !== 'undefined' ? window.location.href : '';
    const poundIndex = url.indexOf('#');
    const urlHasIdToOpen = poundIndex !== -1 && url.slice(poundIndex + 1);
    const openCard = urlHasIdToOpen.length && urlHasIdToOpen === cardId;
    if (openCard && window !== undefined) {
      setIsExpanded(openCard);
      setTimeout(() => {
        window.scrollTo({
          top: anchorRef.current.offsetTop - 100,
          behavior: 'smooth'
        })
      }, 300);
    }
  }, []);
  const {collapsedView, expandedView} = children;
  const {expanderDefault, collapserDefault, link, checked} = useStaticQuery(graphql`
    query {
      expanderDefault: allFile(
        filter: {
          sourceInstanceName: { eq: "otherImages" }
          name: { eq: "expander" }
        }
      ) {
        nodes {
          name
          publicURL
        }
      }
      collapserDefault: allFile(
        filter: {
          sourceInstanceName: { eq: "otherImages" }
          name: { eq: "collapser" }
        }
      ) {
        nodes {
          name
          publicURL
        }
      }
      link: allFile(
        filter: {
          sourceInstanceName: { eq: "otherImages" }
          name: { eq: "link" }
        }
      ){
        nodes {
          name
          publicURL
        }
      }
      checked: allFile(
        filter: {
          sourceInstanceName: { eq: "otherImages" }
          name: { eq: "checked" }
        }
      ){
        nodes {
          name
          publicURL
        }
      }
    }
  `);

  const copyLink = (ev) => {
    ev.stopPropagation();
    const url = typeof window !== 'undefined' ? window.location.href : '';
    const urlSliced = url.includes('#') ? url.slice(0, url.indexOf('#') + 1) : url + '#';
    const textArea = document.createElement('textarea');
    textArea.innerText = urlSliced + cardId;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    textArea.remove();
    toast.success(<span className={styles['toast-content']}><img src={checked.nodes[0].publicURL}/> Link copied!</span>)
  }

  const collapserToUse = collapser ? collapser : collapserDefault.nodes[0].publicURL;
  const expanderToUse = expander ? expander : expanderDefault.nodes[0].publicURL;

  return (
    <div
      ref={anchorRef}
      className={styles['expanding-card']}
      style={{marginBottom: smallCard || clientCard ? '0' : '24px'}}
    >
      <ToastContainer position="bottom-right"
                      hideProgressBar={true}
                      autoClose={1500}
                      closeButton={false}
                      className={styles['toast-container']}/>
      <HeaderButton
        role={'button'}
        onKeyDown={e => {
          if (e.key !== 13) return;
          setIsExpanded(!isExpanded);
        }}
        className={`${styles['expanding-card__heading']}${isExpanded && expandedCardHeaderClassName ? ` ${expandedCardHeaderClassName}` : ''}`}
        clientCard={clientCard}
        custom={custom}
        onClick={() => setIsExpanded(!isExpanded)}
        tabIndex={-11}
      >
        {img && (
          <Icon
            src={img}
            clientCard={clientCard}
            alt="service icon"
          />
        )}

        {collapsedView}

        <div className={styles['expanding-card__btn-container']}>
          {hasLink && isExpanded &&
          <img
            onClick={(e) => copyLink(e)}
            className={styles['link-icon']}
            alt={'link icon'}
            src={link.nodes[0].publicURL}/>}

          {!noIndicator && <img
            src={isExpanded ? collapserToUse : expanderToUse}
            alt={isExpanded ? 'minus icon' : 'plus icon'}
          />}
        </div>
      </HeaderButton>

      <Text
        expanded={isExpanded}
      >
        {expandedView}
      </Text>
    </div>
  );
};

export default ExpandingCard;
