import { toast } from 'react-toastify';
import './Toast.scss';

export default {
  success(msg, options = {}) {
    return toast.success(msg, {
      ...options,
      className: 'toast-success-container'
    });
  }
}
